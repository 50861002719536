import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, Link } from "react-router-dom";
import { auth, db, logout, testdb } from "../../firebase";
import classes from "./Home.module.css";

import { Divider, Select, Row, Col, Card, Image } from "antd";
import { Layout, Typography } from "antd";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
  PlusCircleOutlined,
  ConsoleSqlOutlined,
} from "@ant-design/icons";

import { query, collection, getDocs, where, addDoc, doc, getDoc } from "firebase/firestore";

import { usePartnerStore } from "../store/Store";

function Home() {
  const [hdrs, setHDRs] = useState([]);
  const [selectedHDR, setSelectedHDR] = useState("");
  const partners = usePartnerStore((state) => state.partners);

  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const navigate = useNavigate();

  const { Option } = Select;
  const { Header, Content, Footer } = Layout;
  const { Title } = Typography;

  const fetchUserName = async () => {
    try {
      const docRef = doc(db, "users", user?.uid);
      const docSnap = await getDoc(docRef);
      const data = docSnap.data();

      setName(data.name);
    } catch (err) {
      console.error(err);
      console.error("An error occured while fetching user data");
    }
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    fetchUserName();
  }, [user, loading]);

  return (
    <>
      <Header style={{ background: "#fdfdfd", paddingLeft: "16px", paddingTop: "16px" }}></Header>
      <Content style={{ background: "#fdfdfd", padding: 24, paddingTop: 0 }}>
        <Divider />
        <Row>
          <Col span={24}>
            {partners.map((partner) => {
              return (
                <Card
                  key={partner.name}
                  hoverable
                  title={partner.name}
                  className={classes.PartnerCard}
                  onClick={() => navigate(`/partner/${partner.name.replace(/\s/g, "")}`)}
                  actions={[
                    // <SettingOutlined key="setting" onClick={()=> console.log('Settings')}/>,
                    <EditOutlined key="edit" />,
                  ]}
                >
                  <Image
                    // width={150}
                    alt="Partnerlogo"
                    preview={false}
                    src={partner.logoPath}
                    className={classes.PartnerLogo}
                  />
                </Card>
              );
            })}
            <Card
              hoverable
              className={classes.PartnerCard}
              actions={[
                // <SettingOutlined key="setting" onClick={()=> console.log('Settings')}/>,
                <EditOutlined key="edit" onClick={() => console.log("Edit")} />,
              ]}
            >
              <PlusCircleOutlined
                style={{
                  fontSize: "40px",
                  height: "123px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "gray",
                }}
              />
            </Card>
          </Col>
        </Row>
      </Content>
      <Footer style={{ background: "#fdfdfd", textAlign: "center" }}>
        Ant Design ©2022 Created by Ant UED
      </Footer>
    </>
  );
}

export default Home;
