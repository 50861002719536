import React, { useState, useEffect } from "react";
import classes from "./Partner.module.css";
import { useAuthState } from "react-firebase-hooks/auth";
import { useParams, useNavigate } from "react-router-dom";
import { auth, db } from "../../firebase";
import {
  query,
  collection,
  getDocs,
  where,
  doc,
  getDoc,
  setDoc,
  addDoc,
  deleteDoc,
} from "firebase/firestore";

import { Row, Col, Table, Tag, Button, Popconfirm, Select, Layout, Divider, ConfigProvider } from "antd";
import { DeleteFilled, DeleteOutlined } from "@ant-design/icons";
import { Typography } from "antd";

import { v4 as uuid } from "uuid";
import { usePartnerStore } from "../store/Store";
import { async } from "@firebase/util";

function Partner() {
  const { partnerName } = useParams();
  const [user, loading, error] = useAuthState(auth);
  const [color, setColor] = useState({
    primaryColor: "#e3051b",
  });
  const [name, setName] = useState("");
  const [segments, setSegments] = useState([]);
  const [sharedSegments, setSharedSegments] = useState([]);
  const [selectedSegment, setSelectedSegment] = useState([]);
  const [selectedSharedSegment, setSelectedSharedSegment] = useState([]);
  const [isShareButtonDisaled, setShareButtonDisabled] = useState(true);
  const [isUnshareButtonDisaled, setUnshareButtonDisabled] = useState(true);

  const partners = usePartnerStore((state) => state.partners);
  const currentPartner = partners.find((p) => p.name.replace(/\s/g, "") === partnerName);
  const navigate = useNavigate();

  const { Title, Text } = Typography;
  const { Option } = Select;
  const { Header, Content, Footer, Sider } = Layout;

  /**
   * Gets current username
   */
  const fetchUserName = async () => {
    try {
      const docRef = doc(db, "users", user?.uid);
      const docSnap = await getDoc(docRef);
      const data = docSnap.data();
      setName(data.name);
    } catch (err) {
      console.error(err);
      console.error("An error occured while fetching user data");
    }
  };

  /**
   * Gets all segments
   */
  const getAllSegments = async () => {
    // get targetings to share fromn 3337
    const colletionRef = collection(db, "SharedTargetings", "3337", "Targetings");
    const queryStatement = query(colletionRef);
    const snapshot = await getDocs(queryStatement);
    const transferData = [];
    const sharedSegmentsData = [];

    snapshot.forEach((doc) => {
      transferData.push({
        key: uuid(),
        externalID: doc.data().externalID,
        name: doc.data().name,
        description: doc.data().description,
        price: 0,
        targetingToShareID: doc.id,
        docID: "",
        network: currentPartner.networkIDs[0],
      });
    });

    // get shared targetings from network id
    if (currentPartner.networkIDs.length > 1) {
      for (const network in currentPartner.networkIDs) {
        const myCollRef = collection(
          db,
          "SharedTargetings",
          `${currentPartner.networkIDs[network]}`,
          "Targetings"
        );
        const myQuery = query(myCollRef);
        const mySnapshot = await getDocs(myQuery);

        mySnapshot.forEach(async (myDoc) => {
          sharedSegmentsData.push({
            key: uuid(),
            externalID: myDoc.data().externalID,
            name: myDoc.data().name,
            description: myDoc.data().description,
            price: myDoc.data().price,
            docID: myDoc.id,
            network: currentPartner.networkIDs[network],
          });
        });
      }
    } else {
      const myCollRef = collection(db, "SharedTargetings", `${currentPartner.networkIDs}`, "Targetings");
      const myQuery = query(myCollRef);
      const mySnapshot = await getDocs(myQuery);

      mySnapshot.forEach(async (myDoc) => {
        sharedSegmentsData.push({
          key: uuid(),
          externalID: myDoc.data().externalID,
          name: myDoc.data().name,
          description: myDoc.data().description,
          price: myDoc.data().price,
          docID: myDoc.id,
          network: currentPartner.networkIDs[0],
        });
      });
    }

    if (sharedSegmentsData.length >= 1) {
      // fill empty entries in each shared segment list
      sharedSegmentsData.forEach((sharedSegment) => {
        // find corresponding left side segment
        let leftSideSegment = transferData.find((lss) => lss.externalID === sharedSegment.externalID);

        sharedSegment.name = leftSideSegment.name;
        sharedSegment.description = leftSideSegment.description;
      });
    }
    await setSharedSegments(sharedSegmentsData);
    await setSegments(transferData);
  };

  /**
   * Gets all segments shared eith {currentpartner}
   * @param {*} currentPartner
   */
  const getSharedSegments = async (currentPartner) => {};

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    fetchUserName();
    getAllSegments();
    getSharedSegments(currentPartner);
  }, [user, loading]);

  const shareSegment = (event) => {
    selectedSegment.forEach((seg) => {
      setSharedSegments((prevState) => [
        ...prevState,
        {
          key: uuid(),
          externalID: segments.find((lss) => lss.externalID === seg.externalID).externalID,
          name: segments.find((lss) => lss.externalID === seg.externalID).name,
          description: segments.find((lss) => lss.externalID === seg.externalID).description,
          price: seg.price,
          targetingToShareID: segments.find((lss) => lss.externalID === seg.externalID).targetingToShareID,
          docID: segments.find((lss) => lss.externalID === seg.externalID).docID,
          network: currentPartner.networkIDs[0],
        },
      ]);
    });
  };

  const unshareSegment = (event) => {
    selectedSharedSegment.forEach((seg) => {
      const selectedSharedSegmentIndex = sharedSegments.findIndex((segment) => segment.key === seg.key);

      setSharedSegments([
        ...sharedSegments.slice(0, selectedSharedSegmentIndex),
        ...sharedSegments.slice(selectedSharedSegmentIndex + 1),
      ]);
    });
  };

  const saveSharedSegments = async () => {
    sharedSegments.forEach(async (segment) => {
      if (segment.docID === "") {
        const sharedTargetingsRef = collection(db, "SharedTargetings");
        setDoc(doc(sharedTargetingsRef, `${segment.network}`), {});
        const networkRef = doc(sharedTargetingsRef, `${segment.network}`);
        const targetingsRef = collection(networkRef, "Targetings");

        await addDoc(targetingsRef, {
          externalID: segment.externalID,
          price: segment.price,
          name: segment.name,
          description: segment.description,
        }).then(() => {
          getAllSegments();
        });
      } else {
        const sharedTargetingsRef = doc(
          db,
          "SharedTargetings",
          `${segment.network}`,
          "Targetings",
          segment.docID
        );
        await setDoc(sharedTargetingsRef, {
          externalID: segment.externalID,
          price: segment.price,
          name: segment.name,
          description: segment.description,
        }).then(console.log("New Document added with ID:", segment.externalID));
      }
    });
  };

  const deleteSharedSegment = async (segment) => {
    if (segment.docID === "") {
      await deleteDoc(
        doc(db, "SharedTargetings", `${segment.network}`, "Targetings", segment.targetingToShareID)
      ).then((result) => {
        const selectedSharedSegmentIndex = sharedSegments.findIndex((sharedSegment) => {
          return sharedSegment.docID === segment.docID;
        });
        setSharedSegments([
          ...sharedSegments.slice(0, selectedSharedSegmentIndex),
          ...sharedSegments.slice(selectedSharedSegmentIndex + 1),
        ]);
      });
    } else {
      await deleteDoc(doc(db, "SharedTargetings", `${segment.network}`, "Targetings", segment.docID)).then(
        (result) => {
          // TODO: Reload shared segment list by updating the sharedSegment list after deletion
          const selectedSharedSegmentIndex = sharedSegments.findIndex((sharedSegment) => {
            return sharedSegment.docID === segment.docID;
          });
          setSharedSegments([
            ...sharedSegments.slice(0, selectedSharedSegmentIndex),
            ...sharedSegments.slice(selectedSharedSegmentIndex + 1),
          ]);
        }
      );
    }
  };

  const allSegmentColumns = [
    { dataIndex: "externalID", title: "externalID" },
    {
      dataIndex: "name",
      title: "Name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend"],
    },
    {
      dataIndex: "description",
      title: "Beschreibung",
    },
  ];

  const sharedSegmentColumns = [
    { dataIndex: "externalID", title: "externalID" },
    {
      dataIndex: "name",
      title: "Name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend"],
    },
    {
      dataIndex: "network",
      title: "Netzwerk",
      render: (network, record) => {
        if (currentPartner.networkIDs.length > 1 && record.docID === "") {
          return (
            <Select
              defaultValue={network}
              style={{ width: 80 }}
              onChange={(selNetwork) => {
                record.network = selNetwork;
              }}
            >
              {currentPartner.networkIDs.map((networkID) => {
                return (
                  <Option key={uuid()} value={networkID}>
                    {networkID}
                  </Option>
                );
              })}
            </Select>
          );
        } else {
          return network;
        }
      },
    },
    {
      dataIndex: "price",
      title: "Preis",
      render: (price) => {
        return (
          <span>
            {new Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "EUR",
            }).format(price / 100)}
          </span>
        );
      },
    },
    {
      title: "",
      dataIndex: "operation",
      render: (_, record) => (
        <Popconfirm title="Segment nicht mehr teilen?" onConfirm={() => deleteSharedSegment(record)}>
          <DeleteOutlined style={{ color: color.primaryColor }} />
        </Popconfirm>
      ),
    },
  ];

  const rowSelectionAllSegments = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedSegment(selectedRows);
      if (selectedRows.length == 0) {
        setShareButtonDisabled(true);
      } else {
        setShareButtonDisabled(false);
      }
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const rowSelectionSharedSegments = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedSharedSegment(selectedRows);
      if (selectedRows.length == 0) {
        setUnshareButtonDisabled(true);
      } else {
        setUnshareButtonDisabled(false);
      }
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <>
      <Header style={{ background: "#fdfdfd", paddingLeft: "16px", paddingTop: "16px" }}>
        <Title level={2}>{currentPartner.name}</Title>
      </Header>
      <Content style={{ background: "#fdfdfd", padding: 24, paddingTop: 0 }}>
        <Divider />

        <Row type="flex" justify="center" align="top">
          <Col span={10}>
            {/* Left Table with all segments */}
            <Title level={5}>
              Liste aller gebuchten PNTA Targetings <small>({segments.length})</small>
            </Title>
            <Table
              // rowSelection={rowSelection}
              columns={allSegmentColumns}
              dataSource={segments}
              pagination={false}
              rowSelection={{
                type: "checkbox",
                ...rowSelectionAllSegments,
              }}
            />
          </Col>
          <Col span={4}>
            {/* Buttons to shared and un-share a segments */}
            <Row type="flex" justify="center" align="middle">
              <Button
                onClick={shareSegment}
                className={classes.ShareButton}
                type="primary"
                disabled={isShareButtonDisaled}
              >
                Segment(e) vormerken &gt;
              </Button>
            </Row>
            {/* <Row>
                            <Button 
                                onClick={unshareSegment} 
                                className={classes.UnshareButton} 
                                type="primary"
                                disabled={isUnshareButtonDisaled}
                            > 
                                &lt; 
                            </Button>
                        </Row> */}
          </Col>
          <Col span={10}>
            {/* Right Table with all shared segments */}
            <Title level={5}>
              Liste der geteilten PNTA Segmente <small>({sharedSegments.length})</small>
            </Title>
            <Table
              // rowSelection={rowSelection}
              columns={sharedSegmentColumns}
              dataSource={sharedSegments}
              pagination={false}
            />
            <Button
              onClick={saveSharedSegments}
              type="primary"
              className={classes.SaveButton}
              disabled={false}
            >
              Speichern und teilen
            </Button>
          </Col>
        </Row>
      </Content>
      <Footer style={{ background: "#fdfdfd", textAlign: "center" }}>
        Ant Design ©2022 Created by Ant UED
      </Footer>
    </>
  );
}

export default Partner;
