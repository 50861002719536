import create from 'zustand';

import ASLogo from '../../assets/images/partner/AdvertisingServices.png';
import nqyerLogo from '../../assets/images/partner/nqyer.png';
import KineticLogo from '../../assets/images/partner/kinetic.png';
import WeischerLogo from '../../assets/images/partner/weischer.png';
import OMDLogo from '../../assets/images/partner/OMD.png';
import areaSolutionsLogo from '../../assets/images/partner/areaSolutions.png';
import ActiveAgentLogo from '../../assets/images/partner/activeagent.png';

const useStore = create(
    set => ({
        partners: [
            {
                'name' : 'OTTO Advertising Services',
                'logoPath': ASLogo,
                'networkIDs': [3337]
            }, {
                'name' : 'NQYER',
                'logoPath': nqyerLogo,
                'networkIDs': [3580]
            },{
                'name' : 'Kinetic',
                'logoPath': KineticLogo,
                'networkIDs': [3186]
            },{
                'name' : 'Weischer Media',
                'logoPath': WeischerLogo,
                'networkIDs': [3155]
            },{
                'name' : 'OMD',
                'logoPath': OMDLogo,
                'networkIDs': [3641]
            },{
                'name' : 'areaSolutions',
                'logoPath': areaSolutionsLogo,
                'networkIDs': [3644]
            },{
                'name' : 'Active Agent Testnetzwerke',
                'logoPath': ActiveAgentLogo,
                'networkIDs': [16, 1030]
            }
        ],
    addPartner: partner =>
        set(state => ({
            partners: [
                {
                    name: partner.name,
                    id: Math.random() * 100 + '',
                    section: partner.section
                },
                ...state.partners
            ]
        }
    )),
    removePartner: id =>
        set(state => ({
            partners: state.partners.filter(partner => partner.id !== id)
        }
    )),
    updatePartner: partner =>
        set(state => ({
            partners: state.partners.map(item => {
                if (item.id === partner.id) {
                return {
                    ...item,
                    name: partner.name,
                    section: partner.section
                };
                } else {
                return item;
                }
            })
        }))
}));

export const usePartnerStore = useStore;
