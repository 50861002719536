import classes from "./App.module.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/Login/Login";
import Reset from "./components/Reset/Reset";
import Home from "./components/Home/Home";
import Partner from "./components/Partner/Partner";

import { Divider, Select, Row, Col, Card, Image, Menu } from "antd";
import { Layout, Typography } from "antd";
import { logout, db } from "./firebase";
import { LogoutOutlined } from "@ant-design/icons";

import ASLogo from "./assets/images/partner/AdvertisingServices.png";
import nqyerLogo from "./assets/images/partner/nqyer.png";
import KineticLogo from "./assets/images/partner/kinetic.png";
import WeischerLogo from "./assets/images/partner/weischer.png";
import OMDLogo from "./assets/images/partner/OMD.png";
import areaSolutionsLogo from "./assets/images/partner/areaSolutions.png";
import ActiveAgentLogo from "./assets/images/partner/activeagent.png";
import { query, collection, getDocs, where, addDoc, doc, getDoc } from "firebase/firestore";

function App() {
  const { Header, Content, Footer, Sider } = Layout;
  const { Title } = Typography;

  const handleLogOut = () => {
    logout();
  };

  const menuItems = [{ label: " Abmelden", key: "lougout", icon: <LogoutOutlined />, onClick: handleLogOut }];

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider width={240}>
        <div className={classes.Logo}></div>
        <Title level={5} className={classes.SubTitle}>
          PNTA Segment Sharing
        </Title>
        <Menu theme="dark" mode="inline" items={menuItems} />
      </Sider>

      <Layout>
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/reset" element={<Reset />} />
            <Route path="/home" element={<Home />} />
            <Route path="/partner/:partnerName" element={<Partner />} />
          </Routes>
        </Router>
      </Layout>
    </Layout>
  );
}

export default App;
